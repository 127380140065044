import React from "react";
import Form from "../../components/user/form";
import Layout from "../../components/layouts/verticalLayout";

const FormPage = () => {
  return (
    <Layout
      body={
        <>
          <Form />
        </>
      }
    />
  );
};

FormPage.propTypes = {};

export default FormPage;
