import React from "react";
import PropTypes from "prop-types";
import { debounce } from "throttle-debounce";
import {
  ListItems,
  UlItems,
  LiItems,
  ButtonReset,
  IconReset,
  ButtonItem,
  Loading,
} from "./style";
import { ErrorMessage } from "formik";
import TextErrorMessage from "../TextErrorMessage";

class Autocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "", isLoading: false, items: [], initialized: false };
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
  }

  componentDidUpdate(prevProps) {
    const { name } = this.props.field;
    const { inArrayList } = this.props;
    const { initialized } = this.state;

    let initialValue = null;
    let prevInitialValue = null;
    if (
      inArrayList &&
      this.props.form.initialValues[name.split(".")[0]] &&
      this.props.form.initialValues[name.split(".")[0]][name.split(".")[1]]
    ) {
      const names = name.split(".");
      initialValue =
        this.props.form.initialValues[names[0]][names[1]][names[2]];
      prevInitialValue =
        prevProps.form.initialValues[names[0]][names[1]][names[2]];
    } else {
      initialValue = this.props.form.initialValues[name];
      prevInitialValue = prevProps.form.initialValues[name];
    }
    if (
      prevInitialValue !== initialValue ||
      (prevInitialValue &&
        initialValue &&
        prevInitialValue === initialValue &&
        !initialized)
    ) {
      this.setValue(initialValue);
    }
    if (
      prevProps.field.value !== this.props.field.value &&
      this.props.field.value.length === 0
    ) {
      this.resetStatus();
    }
  }

  resetStatus() {
    this.setState({ value: "", items: [] });
  }

  setValue = async (newValue) => {
    const { unSaveValueOnselected, optionValue, optionLabel, onSearch } =
      this.props;
    const suggestions = await onSearch(newValue, true);
    const item = suggestions.find((itm) => itm[optionValue] === newValue);
    if (item) {
      this.setState({
        value: unSaveValueOnselected ? "" : item[optionLabel],
        initialized: true,
      });
    } else {
      this.setState({ initialized: true });
    }
  };

  handlerOnchangeValue = (event) => {
    this.setState({ value: event.target.value }, () => {
      this.autocompleteSearchDebounced(this.state.value);
    });
  };

  autocompleteSearch = async (value) => {
    const { onSearch } = this.props;

    this.setState({
      isLoading: true,
    });

    const suggestions = await onSearch(value);
    this.setState({
      isLoading: false,
      items: suggestions,
    });
  };

  resetItems = () => {
    this.setState({ items: [], value: "" });
  };

  onSelectedItem = (item) => {
    const {
      form,
      optionLabel,
      optionValue,
      onSelect,
      unSaveValueOnselected,
      field: { name },
    } = this.props;

    form.setFieldValue(name, item[optionValue]);
    this.setState({
      items: [],
      value: unSaveValueOnselected ? "" : item[optionLabel],
    });
    if (onSelect) {
      onSelect(item);
    }
  };

  renderItemList = (items) => {
    const { renderItems, textButtonReset, optionLabel, optionValue } =
      this.props;
    return (
      <ListItems>
        <UlItems>
          {items.map((item) => {
            return (
              <LiItems key={item[optionValue]}>
                <ButtonItem onClick={() => this.onSelectedItem(item)}>
                  {renderItems ? renderItems(item) : item[optionLabel]}
                </ButtonItem>
              </LiItems>
            );
          })}
        </UlItems>
        {items.length ? (
          <ButtonReset type="button" onClick={() => this.resetItems()}>
            <IconReset className="fas fa-eraser"></IconReset>
            {textButtonReset ? textButtonReset : "Reset"}
          </ButtonReset>
        ) : null}
      </ListItems>
    );
  };

  render() {
    const {
      field: { name, onChange, ...resInput },
      id,
      labelField,
      placeholder,
      className,
      divClassName,
      readOnly,
      mandatory,
      maxLength,
      visible,
      tooltip,
      typeStyleErrorMessage,
    } = this.props;

    const { value, items, isLoading } = this.state;
    return (
      <div
        id={`${id}_Container`}
        className={`${
          visible !== undefined && visible === false ? "field-hidden" : ""
        } form-group`}
      >
        {labelField && (
          <div
            className={`labelDiv ${divClassName} no-padding`}
            title={tooltip || labelField}
          >
            <label
              id={`${id}_labelField`}
              className={
                mandatory ? "control-label mandatory" : "control-label"
              }
              htmlFor={id}
            >
              {`${labelField}${mandatory ? " *" : ""}`}
            </label>
          </div>
        )}
        <div className={`${divClassName} no-padding`}>
          <input
            {...resInput}
            id={id}
            name={name}
            placeholder={placeholder}
            type="text"
            maxLength={maxLength || 255}
            className={`inputForm inputText ${className}`}
            readOnly={readOnly}
            value={value}
            onChange={async (event) => {
              this.handlerOnchangeValue(event);
              // onChange(event);
            }}
          />
          {isLoading ? (
            <Loading>
              <i className="fas fa-spinner"></i>
            </Loading>
          ) : null}
          {items.length ? this.renderItemList(items) : ""}
        </div>
        <ErrorMessage
          name={name}
          component={TextErrorMessage}
          type={typeStyleErrorMessage || "default"}
        />
      </div>
    );
  }
}

Autocomplete.propTypes = {
  field: PropTypes.any,
  form: PropTypes.any,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  labelField: PropTypes.string,
  // fieldType: PropTypes.string.isRequired,
  className: PropTypes.string,
  divClassName: PropTypes.string,
  readOnly: PropTypes.bool,
  mandatory: PropTypes.bool,
  maxLength: PropTypes.number,
  visible: PropTypes.bool,
  tooltip: PropTypes.string,
  // format: PropTypes.string,
  // decimals: PropTypes.number,
  typeStyleErrorMessage: PropTypes.string,
  onSearch: PropTypes.func,
  renderItems: PropTypes.func,
  textButtonReset: PropTypes.string,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  onSelect: PropTypes.func,
  unSaveValueOnselected: PropTypes.bool,
  inArrayList: PropTypes.bool,
};

export default Autocomplete;
