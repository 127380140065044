import * as Yup from "yup";

export const dataModel = {
  finca: "",
  stepType: "preCampaign",
  type: "ALMENDRO",
};

export const validationDataModel = () => {
  return Yup.object().shape({
    finca: Yup.string().required("required"),
    stepType: Yup.string().required("required"),
    type: Yup.string().required("required"),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
