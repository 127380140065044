import styled from "styled-components";

export const TotalWeight = styled.div`
  color: ${({ $error }) => ($error ? "red" : "#000")};
`;

export const ContainerTooltip = styled.div`
  max-width: 300px;
`;

export const Ticket = styled.div`
  border: 1px solid #000;
  padding: 15px 20px 20px 20px;
  background-color: #fff;
`;

export const TicketPDF = styled.div`
  border: 1px solid #000;
  padding: 15px 20px 20px 20px;
  background-color: #fff;
  height: 255px;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TicketContainer = styled.div`
  width: 100%;
`;

export const Label = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  margin-right: 5px;
`;

export const Value = styled.div`
  font-size: 0.7rem;
`;

export const Response = styled.div`
  border-bottom: 1px solid #000;
  flex-grow: 1;
  height: 15px;
`;

export const DateInput = styled.div`
  border: 1px solid #000;
  height: 35px;
  width: 100%;
`;

export const Logo = styled.img`
  max-width: 100px;
`;

export const Sample = styled.div`
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
`;
