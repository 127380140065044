import { store } from "../store/index";
import { userLogout, getProfile } from "../store/actions/AuthActions";

export const profile = async () => {
  const result = await store.dispatch(getProfile());
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const logout = async () => {
  await store.dispatch(userLogout());

  return null;
};
