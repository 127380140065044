import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Ticket,
  Label,
  Value,
  Logo,
  Sample,
  DateInput,
  TicketPDF,
  TicketContainer,
  Response,
} from "./ticket.styled";

const DefaultTicket = (props) => {
  const { t } = useTranslation();
  const { finca, sec, isPDF, empty } = props;

  const renderHtmlView = () => {
    return (
      <Ticket className="col-12 mb-4">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-12">
                <Logo
                  src={`/assets/images/logo.png`}
                  alt="logo"
                  className="logo"
                />
              </div>
            </div>
            <div className="row mt-4 mb-1">
              <div className="col-12 d-flex justify-content-start">
                <Label>{`${t("dateTime")}:`}</Label>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-12 d-flex justify-content-start">
                <DateInput></DateInput>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-12 d-flex justify-content-start">
                <Label>{`${t("zone")}:`}</Label>
                <Response></Response>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-12 d-flex justify-content-start">
                <Label>{`${t("point")}:`}</Label>
                <Response></Response>
              </div>
            </div>
            {sec.typeRec === "AMERICANA" && (
              <div className="row mb-1">
                <div className="col-12 d-flex justify-content-start">
                  <Label>{`${t("timeFloor")}:`}</Label>
                  <Response></Response>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12 d-flex justify-content-start">
                <Label>{`${t("finca")}:`}</Label>
                {empty ? <Response></Response> : <Value>{finca.name}</Value>}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-12 text-end">
                <Sample>{t("sample")}</Sample>
              </div>
            </div>
            <div className="row mt-2">
              <div
                className={`col-12 d-flex justify-content-end ${
                  empty ? "mb-1" : ""
                }`}
              >
                <Label>{`${t("sampleResponsible")}:`}</Label>
                {empty ? (
                  <Response></Response>
                ) : (
                  <Value>{finca.sampleManager.name}</Value>
                )}
              </div>
            </div>
            <div className="row">
              <div
                className={`col-12 d-flex justify-content-end ${
                  empty ? "mb-1" : ""
                }`}
              >
                <Label>{`${t("typeRec")}:`}</Label>
                {empty ? <Response></Response> : <Value>{sec.typeRec}</Value>}
              </div>
            </div>
            <div className="row ">
              <div
                className={`col-12 d-flex justify-content-end ${
                  empty ? "mb-1" : ""
                }`}
              >
                <Label>{`${t("campaign")}:`}</Label>
                {empty ? (
                  <Response></Response>
                ) : (
                  <Value>{finca.campaign}</Value>
                )}
              </div>
            </div>
            <div className="row">
              <div
                className={`col-12 d-flex justify-content-end ${
                  empty ? "mb-1" : ""
                }`}
              >
                <Label>{`${t("variety")}:`}</Label>
                {empty ? <Response></Response> : <Value>{sec.variety}</Value>}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12 d-flex justify-content-start mt-1">
            <Label>{`${t("section")}:`}</Label>
            {empty ? <Response></Response> : <Value>{sec.name}</Value>}
          </div>
        </div> */}
      </Ticket>
    );
  };

  const renderPdfView = () => {
    return (
      <TicketPDF className="col-12">
        <TicketContainer>
          <div className="row marginTitleTag">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <div>
                <Logo
                  src={`/assets/images/logo.png`}
                  alt="logo"
                  className="logo"
                />
              </div>
              <div>
                <Sample>{t("sample")}</Sample>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className={`col-12 d-flex align-items-center justify-content-${
                empty ? "center emptyTic" : "end"
              }`}
            >
              <div>
                <div className="row">
                  <div className="col-12 d-flex justify-content-end">
                    <Label>{`${t("sampleResponsible")}:`}</Label>
                    <Value>{empty ? "" : finca.sampleManager.name}</Value>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex justify-content-end">
                    <Label>{`${t("typeRec")}:`}</Label>
                    <Value>{empty ? "" : sec.typeRec}</Value>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 columnIzqTagAlmd">
              <div className="row mb-1">
                <div className="col-12 d-flex justify-content-start">
                  <Label>{`${t("dateTime")}:`}</Label>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-12 d-flex justify-content-start">
                  <DateInput></DateInput>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-12 d-flex justify-content-start">
                  <Label>{`${t("zone")}:`}</Label>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-12 d-flex justify-content-start">
                  <Label>{`${t("point")}:`}</Label>
                </div>
              </div>
              {sec.typeRec === "AMERICANA" && (
                <div className="row mb-1">
                  <div className="col-12 d-flex justify-content-start">
                    <Label>{`${t("timeFloor")}:`}</Label>
                  </div>
                </div>
              )}
            </div>
            <div className="col-6">
              <div className="">
                <div
                  className={`col-12 d-flex justify-content-${
                    empty ? "start" : "end"
                  }`}
                >
                  <Label>{`${t("campaign")}:`}</Label>
                  <Value>{empty ? "" : finca.campaign}</Value>
                </div>
              </div>
              <div className="row">
                <div
                  className={`col-12 d-flex justify-content-${
                    empty ? "start" : "end"
                  }`}
                >
                  <Label>{`${t("variety")}:`}</Label>
                  <Value>{empty ? "" : sec.variety}</Value>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-1">
            <div className="col-12 d-flex justify-content-start">
              <Label>{`${t("finca")}:`}</Label>
              <Value>{empty ? "" : finca.name}</Value>
            </div>
          </div>
          {/* <div className="row mb-1">
            <div className="col-12 d-flex justify-content-start">
              <Label>{`${t("section")}:`}</Label>
              <Value>{empty ? "" : sec.name}</Value>
            </div>
          </div> */}
        </TicketContainer>
      </TicketPDF>
    );
  };

  return <>{isPDF ? renderPdfView() : renderHtmlView()}</>;
};

DefaultTicket.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

export default DefaultTicket;
