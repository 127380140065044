export const PAGE_SIZE_10 = 10;
export const CLEAN_AUTH = "CLEAN_AUTH";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const GET_PROFILE = "GET_PROFILE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const CLEAN_NAV = "CLEAN_NAV";

export const ROLE_ADMIN = "admin";
export const ROLE_STAFF = "staff";

export const LANGUAGE_ES = {
  code: "es",
  label: "language.languages.es",
};
export const LANGUAGE_PT = {
  code: "pt",
  label: "language.languages.pt",
};

export const LANGUAGES = [LANGUAGE_ES, LANGUAGE_PT];

export const STEP_ON = {
  code: "onCampaign",
  label: "onCampaign",
};
export const STEP_PRE = {
  code: "preCampaign",
  label: "preCampaign",
};

export const STEP_TYPES = [STEP_PRE, STEP_ON];

export const TICKET_TYPE_ALMOND_CAMP = {
  code: "almond_campaign",
  label: "almond_campaign",
};

export const TYPE_OLIVAR = {
  code: "OLIVAR",
  label: "olive",
};
export const TYPE_ALMOND = {
  code: "ALMENDRO",
  label: "almond",
};

export const TYPES = [TYPE_ALMOND, TYPE_OLIVAR];
