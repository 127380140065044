import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TicketDefault from "../tickets/default.view";
import AlmdCmpTicket from "../tickets/almondCampaign.view";
import { TICKET_TYPE_ALMOND_CAMP } from "../../../constants/index";

const TicketsPdf = (props) => {
  const { data, empty } = props;
  const { t } = useTranslation();

  const renderSection = (finca, init, to) => {
    let ticketType = "default";
    if (finca.ticketType) {
      ticketType = finca.ticketType;
    }
    const samples = [];
    for (let index = init; index <= to; index++) {
      const section = finca.sections[index];
      if (section) {
        samples.push(
          <div
            key={`${finca.uuid}_${section.name}_${index}`}
            className="col no-padding"
          >
            <div className="row no-margin">
              {ticketType === "default" && (
                <TicketDefault
                  sec={section}
                  finca={finca}
                  isPDF
                  empty={empty}
                />
              )}
              {ticketType === TICKET_TYPE_ALMOND_CAMP.code && (
                <AlmdCmpTicket
                  sec={section}
                  finca={finca}
                  isPDF
                  empty={empty}
                />
              )}
            </div>
          </div>
        );
      }
    }

    return samples;
  };

  const renderFinca = () => {
    const fincas = [];
    for (const finca of data) {
      const pages = Math.ceil(finca.sections.length / 8);
      for (let i = 1; i <= pages; i++) {
        const init = (i - 1) * 8;
        const last = init + 7;
        fincas.push(
          <div
            key={`${finca.uuid}_dd_${i}]`}
            className={`row row-cols-2 page page-${i}`}
          >
            {renderSection(finca, init, last)}
          </div>
        );
      }
    }
    return fincas;
  };

  return (
    <>
      <div className="row pt-5 px-5">
        <div className="col-12">{renderFinca()}</div>
      </div>
    </>
  );
};

TicketsPdf.propTypes = {
  data: PropTypes.any,
};

export default TicketsPdf;
