import styled from "styled-components";

export const TotalWeight = styled.div`
  color: ${({ $error }) => ($error ? "red" : "#000")};
`;

export const EmptyContainer = styled.div`
  margin-right: 15px;
  margin-bottom: 0px !important;
`;

export const LabelEmpty = styled.label`
  margin-bottom: 0px !important;
`;

export const CopiesContainer = styled.div`
  margin-right: 20px;
  padding-left: 15px;
  border-left: 1px solid #ccc;
`;

export const CopiesText = styled.span`
  margin-right: 10px;
  font-size: 0.85rem;
`;

export const Subtitle = styled.div`
  font-size: 1.5rem;
  text-decoration: underline;
`;

export const ContainerTooltip = styled.div`
  max-width: 300px;
`;

export const Ticket = styled.div`
  border: 1px solid #000;
  padding: 15px 20px 20px 20px;
  background-color: #fff;
`;

export const TicketPDF = styled.div`
  border: 1px solid #000;
  padding: 15px 20px 20px 20px;
  background-color: #fff;
  height: 255px;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TicketContainer = styled.div`
  width: 100%;
`;

export const Label = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  margin-right: 5px;
`;

export const Value = styled.div`
  font-size: 0.7rem;
`;

export const DateInput = styled.div`
  border: 1px solid #000;
  height: 35px;
  width: 100%;
`;

export const Logo = styled.img`
  max-width: 100px;
`;

export const Sample = styled.div`
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
`;

export const PdfContainer = styled.div`
  max-height: 500px;
  overflow: auto;
  /* overflow-x: hidden; */
  border: 1px solid #ccc;
  padding: 20px;
  -webkit-box-shadow: inset 10px 10px 25px -16px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 10px 10px 25px -16px rgba(0, 0, 0, 0.75);
  box-shadow: inset 10px 10px 25px -16px rgba(0, 0, 0, 0.75);
`;

export const ContainerTickets = styled.div`
  max-height: 500px;
  overflow: auto;
  border: 1px solid #c5c5c5;
  padding: 30px 20px;
  background-color: #f5f5f5;
`;
