import { Ability, AbilityBuilder } from "@casl/ability";
import { store } from "../../../store";
import { ROLE_ADMIN, ROLE_STAFF } from "../../../constants/index";
import { USER_PERMISSIONS } from "./rolesPermission";

function subjectName(item) {
  if (!item || typeof item === "string") {
    return item;
  }
  return item.__type;
}

const ability = new Ability([], { subjectName });

export const defineRulesFor = (auth) => {
  const { can, rules } = new AbilityBuilder(Ability);
  if (auth.roles) {
    // const enabledTestingMode =
    //   process.env.REACT_APP_ENABLED_TEST_MODE === "true";
    let isAdmin = false;

    if (auth.roles) {
      const roleStaff = auth.roles.find((role) => role === ROLE_STAFF);
      const roleAdmin = auth.roles.find((role) => role === ROLE_ADMIN);
      if (roleStaff) {
        can(USER_PERMISSIONS.STAFF);
      }
      if (roleAdmin) {
        isAdmin = true;
      }
    }

    if (isAdmin) {
      can("manage", "all");
    } else {
      if (auth.userPermissions && auth.userPermissions.length > 0) {
        auth.userPermissions.forEach((per) => {
          can(per);
        });
      }
    }
  }
  return rules;
};

let currentAuth;
store.subscribe(() => {
  const prevAuth = currentAuth;
  currentAuth = store.getState().auth.user;
  if (prevAuth !== currentAuth) {
    ability.update(defineRulesFor(currentAuth ? currentAuth : "anonymous"));
  }
});

export const buildAbilityFor = (role) => {
  return ability.update(
    defineRulesFor(currentAuth ? currentAuth : "anonymous")
  );
};
