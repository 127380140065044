import { createAction } from "redux-actions";
import { SET_LANGUAGE } from "../../constants/index";
import { apiGet } from "../../api/index";
import { urlGetLoginItemTemp, urlFincasConsultSecured } from "../../api/urls";

const generateFilterQuery = ({ filters }) => {
  let filter = "?";

  if (filters) {
    if (filters.name !== undefined) filter += "name=" + filters.name;
    if (filters.finca !== undefined) filter += "&uuid=" + filters.finca;
    if (filters.stepType !== undefined)
      filter += "&stepType=" + filters.stepType;
    if (filters.type !== undefined) filter += "&type=" + filters.type;
  }
  return filter;
};

/**
 * Set TimeZone.
 */
export const setLanguage = createAction(SET_LANGUAGE, (data) => data);

/**
 * Get item.
 */
export const getLoginItem = createAction("GET_ITEM", (token) =>
  apiGet(urlGetLoginItemTemp, [token], false)()
);

/**
 * Filter consultEvaluations.
 */
export const consultFincas = createAction("CONSULT_EVALUATION", (filter) =>
  apiGet(
    urlFincasConsultSecured + generateFilterQuery(filter),
    undefined,
    true
  )()
);
