import styled from "styled-components";

export const HeaderContainer = styled.div`
  height: 55px;
  background: ${({ theme }) => theme.header.backgroundColor};
`;

export const Logo = styled.img`
  max-height: 35px;
`;
