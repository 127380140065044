import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuthUserSelector } from "../../../store/selectors/authSelector";
import * as UserServices from "../../../services/UserServices";
import * as GeneralServices from "../../../services/GeneralServices";
import PATHS from "../../../constants/paths";
import showDialog from "../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";

const AuthConfirmContainer = ({ temporalToken }) => {
  const user = useSelector(getAuthUserSelector);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (temporalToken) {
      try {
        GeneralServices.getItemLoginTemp(temporalToken).then((result) => {
          if (!result) {
            const okAction = () => {
              window.location.href = `${process.env.REACT_APP_LOGIN_URI}`;
            };

            showDialog(
              t(`errorLogin`),
              t(`errorLoginText`),
              `error`,
              false,
              t(`OK`),
              false,
              okAction
            );
          }
        });
      } catch (error) {}
    }
  }, [temporalToken, t]);

  useEffect(() => {
    if (user) {
      UserServices.profile().then((result) => {
        if (result && result.roles) {
          navigate(PATHS.FORM);
        }
      });
    }
  }, [user, navigate]);

  return <>Validando</>;
};

export default AuthConfirmContainer;
