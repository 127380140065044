import styled from "styled-components";

export const ListItems = styled.div`
  background: #fff;
  position: absolute;
  display: block;
  z-index: 10;
  border: 1px solid #ced4da;
  border-radius: 0px 0px 5px 5px;
  min-width: 250px;
`;

export const UlItems = styled.ul`
  list-style: none;
  padding: 0px;
  margin-bottom: 0px;
  max-height: 100px;
  overflow: auto;
`;

export const LiItems = styled.li`
  padding: 5px 10px;
  border-bottom: 1px solid #ced4da;

  &:hover {
    background-color: #e8e8e8;
  }
`;

export const ButtonReset = styled.button`
  padding: 5px 15px;
  background-color: #7d7d7d;
  width: 100%;
  border: 0px;
  color: #fff;
  border-radius: 0px 0px 5px 5px;

  &:hover {
    background-color: #a5a5a5;
  }
`;

export const ButtonItem = styled.button`
  padding: 0px;
  background-color: transparent;
  width: 100%;
  border: 0px;
  text-align: left;
`;

export const IconReset = styled.i`
  margin-right: 10px;
`;

export const Loading = styled.div`
  text-align: right;
  margin-top: calc(-1.5em - 0.75rem - 2px);
  margin-bottom: 0px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
`;
