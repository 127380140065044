import React, { useState } from "react";
import NewEvaluationForm from "./form.view";
import * as GeneralServices from "../../../services/GeneralServices";
import { STEP_PRE, TYPE_ALMOND } from "../../../constants/index";

const FormContainer = () => {
  const [data, setData] = useState(null);
  const [stepSelected, setStepSelected] = useState(STEP_PRE.code);
  const [cultivo, setCultivo] = useState(TYPE_ALMOND.code);
  const [emptyTicket, setEmptyTicket] = useState(false);

  const handleSubmit = async (values) => {
    try {
      const filter = {
        filters: values,
      };
      let result = await GeneralServices.filterData(filter);
      if (result) {
        setData(result);
      }
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const cancel = async (resetForm) => {
    setData(null);
    setEmptyTicket(false);
    setStepSelected(STEP_PRE.code);
    setCultivo(TYPE_ALMOND.code);
    resetForm();
  };

  const handlerFilterFinca = async (value) => {
    const filter = {
      filters: {
        name: value,
        stepType: stepSelected,
        type: cultivo,
      },
    };
    const result = await GeneralServices.filterAllFinca(filter);
    return result;
  };

  const handlerOnSelectStep = async (value) => {
    setStepSelected(value);
  };

  const handlerOnSelectType = async (value) => {
    setCultivo(value);
  };

  const handlerOnEmptyTicket = async (value) => {
    setEmptyTicket(value);
  };

  return (
    <NewEvaluationForm
      data={data}
      emptyTicket={emptyTicket}
      onSubmit={handleSubmit}
      cancelAction={cancel}
      onFilterFinca={handlerFilterFinca}
      onSelectStep={handlerOnSelectStep}
      onSelectType={handlerOnSelectType}
      onEmptyTicket={handlerOnEmptyTicket}
    />
  );
};

FormContainer.propTypes = {};

export default FormContainer;
