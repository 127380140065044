import { store } from "../store/index";
import {
  setLanguage,
  getLoginItem,
  consultFincas,
} from "../store/actions/GeneralActions";
import { setAuthUser, setAuthToken } from "../store/actions/AuthActions";

/**
 * Set setPreferredLanguage language
 */
export const setPreferredLanguage = async (obj) => {
  store.dispatch(setLanguage(obj));
};

export const filterAllFinca = async (filter) => {
  const result = await store.dispatch(consultFincas(filter));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const filterData = async (filter) => {
  const result = await store.dispatch(consultFincas(filter));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

/**
 * Get item
 */
export const getItemLoginTemp = async (token) => {
  let result = await store.dispatch(getLoginItem(token));
  if (result?.payload?.access_token) {
    await store.dispatch(setAuthUser(result.payload.user));
    await store.dispatch(
      setAuthToken({
        access_token: result.payload.access_token,
        token_expiration: result.payload.token_expiration,
      })
    );
    return "OK";
  }
  return null;
};
