import React from "react";
import Header from "../commons/header";
import { BodyContainer } from "./styled";

const VerticalLayout = ({ ...prop }) => {
  return (
    <>
      <div className={`my-container `}>
        <BodyContainer id="page-wrapper" className="container-fluid">
          <Header />
          <div id="page-content-wrapper">
            <div id="page-content">{prop.body}</div>
          </div>
        </BodyContainer>
      </div>
    </>
  );
};

export default VerticalLayout;
