import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getDataTokenSelector } from "../../../../store/selectors/authSelector";
import Languages from "./components/languages";
import UserMenuHeader from "./components/menu";
import { HeaderContainer, Logo } from "./styled";
import ModalForm from "../../../utils/modalForm";

const Header = (props) => {
  const token = useSelector(getDataTokenSelector);

  return (
    <>
      <ModalForm />
      <HeaderContainer className={`row justify-content-between`}>
        <div className="col-2 col-md-6 col-lg-4 align-self-center">
          <Logo
            src={`/assets/images/logo.png`}
            alt="logo"
            className="logo d-none d-lg-block"
          />
        </div>

        <div className="col-6 d-flex justify-content-end align-items-center">
          <div className="row">
            <div className="col-12 d-flex">
              <Languages />
              {token && <UserMenuHeader />}
            </div>
          </div>
        </div>
      </HeaderContainer>
    </>
  );
};

Header.propTypes = {
  withSidebar: PropTypes.bool,
  toggleFunc: PropTypes.func,
};

export default Header;
