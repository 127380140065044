import { Navigate, Routes, Route } from "react-router-dom";
import AuthConfirmPage from "./pages/authConfirm";
import LoginPage from "./pages/login";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import PATHS from "./constants/paths";
import FormPage from "./pages/form";

function App() {
  return (
    <Routes>
      {/* <Route path="/logout" element={<Logout />} /> */}
      <Route path="/user" element={<ProtectedRoutes />}>
        <Route path={PATHS.FORM} exact element={<FormPage />} />
      </Route>

      <Route
        path={PATHS.EXTERNAL_CONFIRM_LOGIN}
        exact
        element={<AuthConfirmPage />}
      />
      <Route path="/login" exact element={<LoginPage />} />
      <Route path="/" element={<Navigate to={"/login"} />} />
    </Routes>
  );
}

export default App;
