const PATHS = {};

PATHS.LOGIN = "/login";
PATHS.HOME = "/user/home";
PATHS.EXTERNAL_CONFIRM_LOGIN = "/authConfirm/:token";
PATHS.EVALUATION = "/user/evaluation/toEval";
PATHS.NEW_EVALUATION = "/user/evaluation/new/:year/:userUuid";
PATHS.EDIT_EVALUATION = "/user/evaluation/edit/:uuid";
PATHS.VIEW_EVALUATION = "/user/evaluation/view/:uuid";
PATHS.LIST_EVALUATION = "/user/evaluation/myTeam/list";
PATHS.RH_LIST_EVALUATION = "/user/evaluation/list";
PATHS.CONFIG = "/user/configuration";

PATHS.FORM = "/user/form";
export default PATHS;
