import React, { useState } from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./form.data.form";
import Autocomplete from "../../utils/InputAutocomplete";
import {
  Subtitle,
  PdfContainer,
  ContainerTickets,
  EmptyContainer,
  CopiesContainer,
  CopiesText,
  LabelEmpty,
} from "./form.styled";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import PdfView from "./pdf.view";
import html2PDF from "jspdf-html2canvas";
import moment from "moment-timezone";
import InputSelect from "../../utils/InputSelect";
import {
  STEP_TYPES,
  TICKET_TYPE_ALMOND_CAMP,
  TYPES,
} from "../../../constants/index";
import TicketDefault from "../tickets/default.view";
import AlmdCmpTicket from "../tickets/almondCampaign.view";

const NewEvaluationForm = (props) => {
  const { t } = useTranslation();
  const [copies, setCopies] = useState(null);

  const {
    handleSubmit,
    cancelAction,
    onFilterFinca,
    data,
    resetForm,
    onSelectStep,
    onSelectType,
    onEmptyTicket,
    emptyTicket,
  } = props;
  const dispatch = useDispatch();

  const renderListFinca = (item) => {
    return item.name;
  };

  const printTags = async (data) => {
    const date = new Date();
    const pages = document.getElementsByClassName("page");
    await html2PDF(pages, {
      jsPDF: {
        format: "a4",
        unit: "mm",
      },
      imageType: "image/jpeg",
      output: `etiquetas_${moment(date).format("DDMMYYYYhhmmss")}`,
    });
  };

  const onSelectCopies = (event) => {
    setCopies(event.target.value);
  };

  const generateTagsTemplate = (data) => {
    return (
      <>
        <PdfContainer>
          <div
            id="pdfData"
            className="mt4"
            style={{
              backgroundColor: "#fff",
              width: "210mm",
              minHeight: "297mm",
              margin: "auto",
            }}
          >
            <PdfView data={data} empty={emptyTicket}></PdfView>
          </div>
        </PdfContainer>
        <div className="row mt-3">
          <div className="col-12 text-center">
            <button
              type="button"
              className="btn btn-md btn-primary"
              title={t("download")}
              onClick={() => printTags(data)}
            >
              {t("download")}
            </button>
          </div>
        </div>
      </>
    );
  };

  const showModalTags = () => {
    let finalData = JSON.parse(JSON.stringify(data));
    if (copies) {
      for (let index = 0; index < Number(copies) - 1; index++) {
        finalData[0].sections.push(data[0].sections[0]);
      }
    }
    dispatch(
      show("ModalForm", {
        body: <>{generateTagsTemplate(finalData)}</>,
        className: "tagModal",
      })
    );
  };

  const renderSection = (finca) => {
    let ticketType = "default";
    if (finca.ticketType) {
      ticketType = finca.ticketType;
    }
    return finca.sections.map((sec) => {
      return (
        <div key={`${finca.uuid}_${sec.name}`} className="col">
          <div className="row no-margin">
            {ticketType === "default" && (
              <TicketDefault
                sec={sec}
                finca={finca}
                empty={emptyTicket}
              ></TicketDefault>
            )}
            {ticketType === TICKET_TYPE_ALMOND_CAMP.code && (
              <AlmdCmpTicket
                sec={sec}
                finca={finca}
                empty={emptyTicket}
              ></AlmdCmpTicket>
            )}
          </div>
        </div>
      );
    });
  };

  const renderFinca = () => {
    return data.map((finca) => {
      return (
        <div
          key={finca.uuid}
          className="row row-cols-1 row-cols-md-1 row-cols-lg-2"
        >
          {renderSection(finca)}
        </div>
      );
    });
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="row justify-content-center">
              <Subtitle className="col-11 col-md-11 mt-4 mb-4">
                {t("generate")}
              </Subtitle>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 col-md-11">
                <div className="row">
                  <div className="col-12 mt-2">
                    <Field
                      id="stepType"
                      name="stepType"
                      labelField={t("filterStepType")}
                      component={InputSelect}
                      placeholder=""
                      optionLabel="label"
                      optionValue="code"
                      onChangeValue={onSelectStep}
                      options={STEP_TYPES.map((item) => ({
                        ...item,
                        label: t(item.label),
                      }))}
                      classNamePrefix="dark"
                      classNameContainer="flex-grow-1"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-2">
                    <Field
                      id="type"
                      name="type"
                      labelField={t("filterType")}
                      component={InputSelect}
                      placeholder=""
                      optionLabel="label"
                      optionValue="code"
                      onChangeValue={onSelectType}
                      options={TYPES.map((item) => ({
                        ...item,
                        label: t(item.label),
                      }))}
                      classNamePrefix="dark"
                      classNameContainer="flex-grow-1"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-2">
                    <Field
                      id="finca"
                      className="form-control"
                      labelField={t("filterFinca")}
                      name="finca"
                      mandatory
                      component={Autocomplete}
                      fieldType="text"
                      onSearch={onFilterFinca}
                      optionValue="uuid"
                      optionLabel="name"
                      textButtonReset={t("clear")}
                      renderItems={renderListFinca}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center mt-5 mb-5">
              <div className="col-11 text-center">
                <button
                  type="button"
                  onClick={() => cancelAction(resetForm)}
                  className="btn btn-md btn-cancel me-2"
                >
                  {t("reset")}
                </button>
                <button type="submit" className="btn btn-md btn-primary me-2">
                  {t("getData")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {data && (
        <>
          <div className="row justify-content-center mb-4">
            <Subtitle className="col-12 col-md-4 col-lg-3 text-start mb-3 mb-md-0 d-flex justify-content-center justify-content-md-start">
              {t("tickets")}
            </Subtitle>
            <div className="col-12 col-md-4 col-lg-7 d-flex align-items-center justify-content-md-end">
              <EmptyContainer className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={emptyTicket}
                  id="emptyTickets"
                  onChange={(e) => onEmptyTicket(e.target.checked)}
                />
                <LabelEmpty className="form-check-label" htmlFor="emptyTickets">
                  {t("generateEmptyTicket")}
                </LabelEmpty>
              </EmptyContainer>
              {data && data.length === 1 && data[0].sections.length === 1 && (
                <CopiesContainer className="d-flex align-items-center">
                  <CopiesText>{t("copies")}:</CopiesText>
                  <select name="copies" onChange={(e) => onSelectCopies(e)}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                  </select>
                </CopiesContainer>
              )}

              <button
                type="button"
                onClick={() => showModalTags()}
                className="btn btn-md btn-secondary me-2"
              >
                {t("generatePdf")}
              </button>
            </div>
          </div>
          <div className="row justify-content-center mb-5">
            <ContainerTickets className="col-12 col-md-8 col-lg-10">
              {renderFinca()}
            </ContainerTickets>
          </div>
        </>
      )}
    </>
  );
};

NewEvaluationForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

const NewEvaluationFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "NewEvaluationForm",
})(NewEvaluationForm);

export default NewEvaluationFormF;
