import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Ticket,
  Label,
  Value,
  Logo,
  Sample,
  DateInput,
  TicketPDF,
  TicketContainer,
  Response,
} from "./ticket.styled";

const DefaultTicket = (props) => {
  const { t } = useTranslation();
  const { finca, sec, isPDF, empty } = props;

  const renderHtmlView = () => {
    return (
      <Ticket className="col-12 mb-4">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-12">
                <Logo
                  src={`/assets/images/logo.png`}
                  alt="logo"
                  className="logo"
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 d-flex justify-content-start">
                <Label>{`${t("date")}:`}</Label>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-start">
                <DateInput></DateInput>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-start">
                <Label>{`${t("campaign")}:`}</Label>
                {empty ? (
                  <Response></Response>
                ) : (
                  <Value>{finca.campaign}</Value>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-start">
                <Label>{`${t("finca")}:`}</Label>
                {empty ? <Response></Response> : <Value>{finca.name}</Value>}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-12 text-end">
                <Sample>{t("sample")}</Sample>
              </div>
            </div>
            <div className="row mt-2">
              <div
                className={`col-12 d-flex justify-content-${
                  empty ? "start" : "end"
                }`}
              >
                <Label>{`${t("cultivo")}:`}</Label>
                {empty ? <Response></Response> : <Value>{finca.type}</Value>}
              </div>
            </div>
            <div className="row">
              <div
                className={`col-12 d-flex justify-content-${
                  empty ? "start" : "end"
                }`}
              >
                <Label>{`${t("variety")}:`}</Label>
                {empty ? <Response></Response> : <Value>{sec.variety}</Value>}
              </div>
            </div>
            {finca.type === "OLIVAR" && (
              <div className="row">
                <div
                  className={`col-12 d-flex justify-content-${
                    empty ? "start" : "end"
                  }`}
                >
                  <Label>{`${t("technical")}:`}</Label>
                  {empty ? (
                    <Response></Response>
                  ) : (
                    <Value>{finca.technical.name}</Value>
                  )}
                </div>
              </div>
            )}
            <div className="row">
              <div
                className={`col-12 d-flex justify-content-${
                  empty ? "start" : "end"
                }`}
              >
                <Label>{`${t("responsible")}:`}</Label>
                {empty ? (
                  <Response></Response>
                ) : (
                  <Value>{finca.manager.name}</Value>
                )}
              </div>
            </div>

            <div className="row">
              <div
                className={`col-12 d-flex justify-content-${
                  empty ? "start" : "end"
                }`}
              >
                <Label>{`${t("sampleResponsible")}:`}</Label>
                {empty ? (
                  <Response></Response>
                ) : (
                  <Value>{finca.sampleManager.name}</Value>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-start">
            <Label>{`${t("section")}:`}</Label>
            {empty ? <Response></Response> : <Value>{sec.name}</Value>}
          </div>
        </div>
      </Ticket>
    );
  };

  const renderPdfView = () => {
    return (
      <TicketPDF className="col-12">
        <TicketContainer>
          <div className="row marginTitleTag">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <div>
                <Logo
                  src={`/assets/images/logo.png`}
                  alt="logo"
                  className="logo"
                />
              </div>
              <div>
                <Sample>{t("sample")}</Sample>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className={`col-12 d-flex align-items-center justify-content-${
                empty ? "center emptyTic" : "end"
              }`}
            >
              <div>
                {finca.type === "OLIVAR" && (
                  <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                      <Label>{`${t("technical")}:`}</Label>
                      <Value>{empty ? "" : finca.technical.name}</Value>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-12 d-flex justify-content-end">
                    <Label>{`${t("responsible")}:`}</Label>
                    <Value>{empty ? "" : finca.manager.name}</Value>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex justify-content-end">
                    <Label>{`${t("sampleResponsible")}:`}</Label>
                    <Value>{empty ? "" : finca.sampleManager.name}</Value>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 columnIzqTag">
              <div className="row">
                <div className="col-12 d-flex justify-content-start">
                  <Label>{`${t("date")}:`}</Label>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12 d-flex justify-content-start">
                  <DateInput></DateInput>
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex justify-content-start">
                  <Label>{`${t("campaign")}:`}</Label>
                  <Value>{empty ? "" : finca.campaign}</Value>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div
                  className={`col-12 d-flex justify-content-${
                    empty ? "start" : "end"
                  }`}
                >
                  <Label>{`${t("cultivo")}:`}</Label>
                  <Value>{empty ? "" : finca.type}</Value>
                </div>
              </div>
              <div className="row">
                <div
                  className={`col-12 d-flex justify-content-${
                    empty ? "start" : "end"
                  }`}
                >
                  <Label>{`${t("variety")}:`}</Label>
                  <Value>{empty ? "" : sec.variety}</Value>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-start">
              <Label>{`${t("finca")}:`}</Label>
              <Value>{empty ? "" : finca.name}</Value>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 d-flex justify-content-start">
              <Label>{`${t("section")}:`}</Label>
              <Value>{empty ? "" : sec.name}</Value>
            </div>
          </div>
        </TicketContainer>
      </TicketPDF>
    );
  };

  return <>{isPDF ? renderPdfView() : renderHtmlView()}</>;
};

DefaultTicket.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

export default DefaultTicket;
